import React from 'react'
import styles from './styles/type1.module.scss'
import {BASE_URL} from "@/api";

interface ButtonType1 {
  link: string
  code: string
  text: string
  id: number | null
  couponLink?: string,
  typeFilter?: string,
  type?: string | null,
  from?: string,
  storeSlug?: string
}

export default function Type1({
  link,
  code,
  text,
  id,
  couponLink = '',
  typeFilter='',
    type,
    from,
    storeSlug
}: ButtonType1) {
  const goLink = () => {
    const href = window.location.href.split('?')[0]
      const urlMaps:any = {
        'valid': `${href}?id=${id}&typeFilter=${typeFilter}`,
        'expiredValid': `${href}?id=${id}`
      }
      const url = type ? urlMaps[type] : `${href}?id=${id}`
      window.open(url, '_blank')
  }
  return (
    <a
        href={link}
        rel="nofollow"
      className={styles.type1}
      onClick={() => {
        if (id === null) {
          const href = window.location.href.split('?')[0]
          window.open(`${href}${couponLink}`, '_blank')
        }else if(from && ['savedOffers', 'category_page'].some(v=>v === from)){
          let host = BASE_URL
          window.open(`${host}/coupons/${storeSlug}?id=${id}`,'_blank')
        }else {
          goLink()
        }
      }}
    >
      <div className={styles.btn}>
        <div className={styles.code_btn}>
          <div className={styles.get_code}>
            <span className={styles.pc}>{text}</span>
            <span className={styles.mobile}>{text}</span>
          </div>
          <span className={styles.hidden_code}>
            {code.slice(code.length - 5, code.length)}
          </span>
        </div>
      </div>
    </a>
  )
}
